import styled, { css } from 'styled-components';
const RegularFont = 'Rubik-Regular';
import { DropdownButton, DropdownMenu } from 'react-bootstrap';

const Layout = styled.div`
  padding: 25px 0px 25px 0px;
  width: 100%;
  max-width: 1246px;
  font-family: ${RegularFont};
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : '100px'};
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'auto'}
  margin: auto;
  display: flex;
  @media (max-width: 767px) {
    padding: 30px 15px 20px;
  }
  `;

const Main = styled.div`
  padding: 0px 25px 25px 25px;
  width: 100%;
`;

const ActivityDropdown = styled(DropdownButton)`
  padding: 0px 15px;
  width: 100%;
  text-align: left;
  margin-top: 0px;
  color: #9c9c9c;
  text-transform: capitalize;
  height: 50px;
  font-family: 'rubik';
  border: 1px solid #9C9C9C;

  > span {
    float: right;
    top: 20px;
    position: absolute;
    right: 10px;
  }

  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    color: #9c9c9c;
    border: 1px solid #9C9C9C;
  }
`;


const CustomMenuItem = styled(DropdownMenu)`
  width: 100%;
  text-transform: capitalize;
  > a {
    &:active, &:hover, &:focus {
      outline: none;
    }
  }
`;
 
const CustomTextArea = styled.textarea`
  font-size: 16px;
  padding: 15px 15px;
  border-radius: 4px;
  border: 1px solid #9C9C9C;
  float: right;
  width: 100%;
  resize: none;
  color: #9c9c9c;
  font-family: 'rubik';
  cursor: ${({ disableArea }) => disableArea && 'not-allowed'};
  
  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: #9c9c9c;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #9c9c9c;
  }

  ::-ms-input-placeholder {
    color: #9c9c9c;
  }
`;

const OnDemandButton = styled.div`
width: 385px;
background: #FD7175;
height: 50px;
display: flex;
justify-content: center;
border-radius: 6px;
cursor: pointer;

${({ disabled }) => disabled === true && css`
opacity: 0.5;
cursor: not-allowed !important;
`}


>span{
  font-size: 18px;
  font-family: 'Rubik-Medium';
  line-height: 18px;
text-align: center;
color: white;
margin: auto;
}
`;

const FirstContainer = styled.div`
  width: 67.1%;
  display: block;
`;

const FormContainer = styled.form`
  width: ${({ editChallenge }) => editChallenge ? '100%' : '65%'};
  float: left;
  display: block;

`;

const TitleContainer = styled.div`
  float: left;
  width: ${({ width }) => width ? width : '100%'};
  padding: 15px 0px 10px 0px;
  margin: ${({ margin }) => margin};
  > div {
    float: left;
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }

  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    width: 50%;
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;

const ImageContainer = styled(TitleContainer)`
  padding: 15px 0px 10px 0px;
  > div {
    position: relative;
  }

  .dropdown-menu {
    width: 50%;
  }

  button#dropdown-basic {
    outline: none;
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
  }

  img {
    width: 40%;
    margin-top: 10px;
    float: left;
    min-height: 150px;
    
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  @media (max-width: 550px) {
    padding: 25px 15px 25px;
  }
`;

const FieldTitle = styled.span`
  font-size: 16px;
  margin-bottom : 15px;
  letter-spacing: 0px;
  line-height: 16.5px
  color: #0D4270;
  font-family: 'Rubik';
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const CustomInput = styled.input`
  font-size: 16px;
  padding: 12px 10px;
  border-radius: 4px;
  border: 1px solid #cdcbcb;
  float: right;
  width: 100%;
  padding-right: 25%;
  
  &:hover, &:active, &:focus {
    outline: none;
  }
`;

const StyledInputNew = styled.input`

font-size: 16px;
padding: 12px 10px;
border-radius: 4px;
border: 1px solid #cdcbcb;
float: right;
width: 100%;
padding-right: 25%;

&:hover, &:active, &:focus {
  outline: none;
}


  margin-top: 10px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cdcbcb;
  height: 50px;
  width: 100%;
  font-size: ${({ locationInput }) => locationInput ? '14px' : '16px'};
  padding: 0 30px 0 20px;;
  color: rgb(153,153,153);
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};

  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }
`;

const ImageInput = styled.label`
  color: ${({ Color }) => Color ? Color : 'white'};
  padding: 9px 20px;
  background-color:${({ bgColor }) => bgColor ? bgColor : '#159fc9'};
  border-radius: 30px;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  line-height: ${({ lineHeight }) => lineHeight && '1.428571429'};
  margin-top: 10px;
  font-family: 'rubik-medium';
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  
  > input {
    opacity: 0;
    z-index: -1;
    position: absolute;
    bottom: ${({imageReload}) => imageReload && '0'};
  }
  
  ${(props) => props.showAtBottom && css`
    position: absolute;
    left: calc(40% + 20px);
    bottom: 0;

    @media (max-width: 500px) {
      left: 0;
      position: static;
    }
  `}
`;

const LabelContainer = styled.div`
  width: 350px;
  display: flex;
  >div:first-child{
    width: 300px;
  }
  >div:last-child{
    width: 50px;
  }
`;

const TitleContainerNew = styled.div`
float: left;
width: 39%;
max-width: 314px;
padding: 15px 0px;
margin: ${({ margin }) => margin};
> div {
  float: left;
  width: 100%;
  position: relative;
}
.main{
  display: inline-block;
  width: 100%;
  >span{
    font-size: 12px;
  }
}

.outerDiv{
  display: flex;
  >div:first-child{
    width: 70%;
  }
  >div:last-child{
    width: 30%;
    display: flex;
    .padding{
      padding-top: 10px;
    }
    >div:first-child{
      height: 100%;
      width: 20%;
      >label{
        margin: 0;
      }
    }
    >div:last-child{
      width: 80%;
    }
  }
}

${({ addMargin }) => addMargin && css`
  > div {
    margin-top: 10px;
  }
`}

@media (max-width: 550px) {
  padding: 30px 15px 30px;
}

svg {
  position: absolute;
  top: 15px;
  right: 0;
}

.dropdown-menu {
  width: 50%;
}
@media (max-width: 1000px){
  width: 100%;
}
`;


const CustomTextAreaNew = styled.textarea`
  font-size: 14px;
  padding: 18px 15px;
  border-radius: 4px;
  border: 1px solid #cdcbcb;
  float: right;
  width: 100%;
  margin-top: 16px;
  resize: none;
  color: rgb(153,153,153);
  cursor: ${({ disableArea }) => disableArea && 'not-allowed'};
  height: 50px;
  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: 15px;
`;

const EditorContainer = styled.div`
  float: left;
  width: 100%;
`;

export { Layout, Main, ActivityDropdown, CustomMenuItem, CustomTextArea, OnDemandButton, FirstContainer, FormContainer, TitleContainer, ImageContainer, FieldTitle, StyledInputNew, CustomInput, ImageInput, LabelContainer, TitleContainerNew, CustomTextAreaNew, ButtonContainer, EditorContainer };