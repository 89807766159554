/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ImageInput, ImageContentContainer, RemovePhotoButton, StyledInputNew } from '../../CreateEvent/styles';
import { HeadingContainer, HeadingMain } from '../OnDemandDetailsPage/style'
import { Layout, Main, ActivityDropdown, CustomMenuItem, OnDemandButton, FirstContainer, FormContainer, TitleContainer, ImageContainer, FieldTitle, TitleContainerNew, ButtonContainer, EditorContainer } from '../CreateOndemandClass/style';
import { getOrientation, resetOrientation, checkImage } from '../../../utils/methods';
import PropTypes from 'prop-types';
import Editor from "../../CKEditor";
import { connect } from 'react-redux';
import _ from 'lodash';
import { EditOndemandEventVideo } from '../../../redux/actions';
import { ImageUrl, imageErrorMessage} from '../../../utils/constants';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import Waiting from '../../Waiting';
import { toast } from 'react-toastify';

class EditOndemandMain extends Component {
  constructor() {
    super();
    this.state = {
      title: '',
      url: '',
      time: '',
      description: '',
      imageName: '',
      imgSrc: '',
      classTypeKey: 'health',
      classCategoryTypeValue: 1,
      pointValue: '50',
      coachId: '',
      flayerName: '',
      buttonstatus: false
    }
  }

  componentDidMount() {
    const { history: { location: { pathname } },fetchOndemandEventDetailsById, ondemandEventDetail, fetchOndemandCategory } = this.props;
    const { coachId, flayerName} = this.state;
    fetchOndemandCategory()
    const StringArray = pathname.split('/');
    if (StringArray[(StringArray.length - 1)] != '') {
      fetchOndemandEventDetailsById(StringArray[(StringArray.length - 1)]);
    } else {
      fetchOndemandEventDetailsById(StringArray[(StringArray.length - 2)]);
    }
    if(!ondemandEventDetail || _.isEmpty(ondemandEventDetail)) {
      return <Waiting />
    }
    else{
      this.setState({
        title: ondemandEventDetail.title,
        imgSrc: ondemandEventDetail.thumbnail_image,
        url: ondemandEventDetail.video_url,
        classTypeKey: ondemandEventDetail.category_name,
        time: ondemandEventDetail.time,
        point_value: ondemandEventDetail.point_value,
        description: ondemandEventDetail.description,
        coachId: coachId,
        flayerName: flayerName,
        imageName: ondemandEventDetail.thumbnail_image,
        classCategoryTypeValue: ondemandEventDetail.hra_category_id
      });
    }
  }

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeURL = (e) => {
    this.setState({
      url: e.target.value,
    });
  };

  onChangeTime = (e) => {
    const numValidate = /^[0-9\b]+$/;
    if (e.target.value === '' || numValidate.test(e.target.value)) {
      this.setState({time: e.target.value})
    }
  };

  removePhoto = () => {
    document.getElementById('event-upload-file').value = '';
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };

  onChange = (e) => {
    let file = e.target.files[0];   
    let fileArr = e.target.files;
    if(e.target.files[0]) {
      if(checkImage(fileArr)) {
        if((file?.size/1000000) <= 20){
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document.getElementById('event-upload-file').value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage
                });
              });
            });
          };
        } else {
          toast.error('Please select image file less than 20MB');
          document.getElementById('event-upload-file').value = '';  
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById('event-upload-file').value = '';
      } 
    }
  };


  onSelectClass = (name, value, key) => {
    this.setState({
      [name]: value,
      classTypeKey: key
    });
  };


  saveOndemandEvent = () => {
    // e.preventDefault();
    const { title, url, classCategoryTypeValue, time, description, pointValue, imgSrc, coachId, flayerName, classTypeKey } = this.state;
    const { EditOndemandEventVideo, ondemandEventDetail } = this.props;
    this.setState({
      buttonstatus: true
    });
    const OndemandEventDetails = {
      id: ondemandEventDetail.id,
      title: title,
      image: imgSrc,
      video_url: url,
      hra_category_id: classCategoryTypeValue,
      time: time,
      point_value: pointValue,
      description: description,
      coach_id: coachId,
      flyer: flayerName
    };
    EditOndemandEventVideo(OndemandEventDetails, classTypeKey);
  };

  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, '');
    trimmedString.length === 0 || trimmedString.length === 7 || trimmedString == '<p></p><p></p>' ? this.setState({ description: "" }) : this.setState({ description: newContent })
  };
  
  checkBase64(image) {
    if(image) {
      let arr = image.split('/');
      if(arr[0] !== 'webinar-video-category-image') {
        return true;
      }
      else {
        return false;
      }
    }
  }

  render() {
    const { title, url, time, description, imageName, imgSrc, classTypeKey, classCategoryTypeValue, pointValue, buttonstatus } = this.state;
    const {ondemandCategory}= this.props;
    if (isUndefined(ondemandCategory) || isNull(ondemandCategory) ) {
      return <Waiting />
    }
    return (
      <Main>
        <Layout>
          <FirstContainer>
            <FormContainer editChallenge={"1"} >
              <HeadingContainer width={"100%"} margin={'15px'}>
                <HeadingMain>Edit A Video Class</HeadingMain>
              </HeadingContainer>
              <TitleContainer>
                <FieldTitle>{"Title Of The Class"}</FieldTitle>
                <StyledInputNew
                  placeholder="Type Title Here..."
                  name="title"
                  onChange={this.onChangeInput}
                  value={title}
                  maxLength="100"
                />
              </TitleContainer>

              <ImageContainer>
                <FieldTitle>{"Upload Image"}</FieldTitle>
                <div>
                  {imageName &&
                    <div>
                      <img src={this.checkBase64(imgSrc) ? imgSrc : `${ImageUrl}/${imgSrc}`}/>
                      <ImageContentContainer>
                        <div>{imageName}</div>
                        <RemovePhotoButton color="#aaaaaa" type="button" onClick={this.removePhoto} >Remove photo</RemovePhotoButton>
                      </ImageContentContainer>
                    </div>
                  }
                  <ImageInput htmlFor="event-upload-file" showAtBottom={imageName !== ''} margin={'0px'} >
                    {imageName ? 'browse new' : 'browse'}
                    <input
                      id="event-upload-file"
                      type="file"
                      name="user"
                      accept=".jpeg, .png, .jpg"
                      multiple={false}
                      onChange={(e) => this.onChange(e)}
                      onClick={(e) => e.target.files[0] && this.onChange(e)}
                    />
                  </ImageInput>
                </div>
              </ImageContainer>
              <TitleContainer>
                <FieldTitle>{"Video URL"}</FieldTitle>
                <StyledInputNew
                  placeholder="Paste URL"
                  name="title"
                  onChange={this.onChangeURL}
                  value={url}
                  maxLength="100"
                />
              </TitleContainer>
              <TitleContainer>
                <FieldTitle>{"Class Category"}:</FieldTitle>
                <ActivityDropdown
                  title={classTypeKey}
                  id="dropdown-recurring"
                >
                  {
                    ondemandCategory.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onSelectClass('classCategoryTypeValue', item['id'], item['name'])}
                        active={classCategoryTypeValue == item['id']}
                      >
                        {item['name']}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </TitleContainer>
              <TitleContainerNew>
                <FieldTitle>{"Class Total Time (in Minutes)"}</FieldTitle>
                <StyledInputNew
                  placeholder="Enter Total Minutes"
                  name="title"
                  onChange={this.onChangeTime}
                  value={time}
                  maxLength="3"
                />
              </TitleContainerNew>
              <TitleContainer >
                <FieldTitle>{"Class Point Value"}</FieldTitle>
                <StyledInputNew
                  name="title"
                  value={pointValue}
                  disabled={'1'}
                />
              </TitleContainer>
              <TitleContainer>
                <FieldTitle>{"About The Class"}</FieldTitle>
                <EditorContainer>
                  <Editor content={description} onChange={this.onChangeDescription} />
                </EditorContainer>
              </TitleContainer>
              <ButtonContainer>
                {buttonstatus ?
                  <OnDemandButton disabled={true} >
                    <span>
                     Save Class
                    </span>
                  </OnDemandButton> :
                  title.length > 0 && time.length > 0 && url.length > 0 && description.length > 0 && pointValue.length > 0 && imgSrc.length > 0 ?
                    <OnDemandButton onClick={() => this.saveOndemandEvent()}>
                      <span>
                       Save Class
                      </span>
                    </OnDemandButton> :
                    <OnDemandButton disabled={true} >
                      <span>
                        Save Class
                      </span>
                    </OnDemandButton>
                }
              </ButtonContainer>
            </FormContainer>
          </FirstContainer>
        </Layout>
      </Main>
    )
  }
}

EditOndemandMain.propTypes = {
  EditOndemandEventVideo: PropTypes.func,
  history: PropTypes.object,
  fetchOndemandEventDetailsById: PropTypes.func.isRequired,
  ondemandEventDetail: PropTypes.object,
  fetchOndemandCategory: PropTypes.func,
  ondemandCategory: PropTypes.array
};

const mapStateToProps = (state) => ({
  ondemandEventDetail: state.events.ondemandEventDetail,
  ondemandCategory: state.events.ondemandCategory
});
  
const mapDispatchToProps = (dispatch) => ({
  EditOndemandEventVideo: (data, category) => dispatch(EditOndemandEventVideo(data, category))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditOndemandMain));
